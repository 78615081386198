import React, {Component} from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import moment from "moment";

import global from "./Global";
import {getTable, hideForm, msg, price2str, str2price, str2date} from "../js/functions";
import db from "../js/database";

class Header extends Component {
    
    localstate = observable({
        error: null
    });

    onChange(name, e) {
        let value = e.target.value;

        global.form[name] = value;
        this.localstate.error = null;
    }

    onFocusout(name, e) {
        let value = e.target.value;

        if (value && (name === "price" || name === "soldPrice")) {
            const price = Number(value.replace(/[^0-9,]/g, "").replace(",", "."));
            value = price2str(round(price));

            global.form[name] = value;
        }

        if (name === "dateOrdered" || name === "dateInstalled" || name === "dateUninstalled") {
            value = value.replace(/[^\d.]/g, ".");

            if (value.length > 0) {
                const valueList = value.split(".");
                const day = valueList[0] ? toFixedLength(valueList[0]) : moment().format("DD");
                const month = valueList[1] ? toFixedLength(valueList[1]) : moment().format("MM");
                const year = valueList[2] ? 
                    valueList[2].length === 2 ? 
                        moment().format("YYYY").substr(0, 2) + valueList[2]
                        : valueList[2].length === 4 ? valueList[2]
                        : moment().format("YYYY")
                    : moment().format("YYYY");
                value = `${day}.${month}.${year}`;
            }

            global.form[name] = value;
        }
    }

    async submitForm() {
        const {profile, selectedItem, formAction, form} = global;

        if (!form.type) {this.formError("type", "Wähle einen Typ aus"); return}
        if (!form.description) {this.formError("description", "Keine Angabe"); return}
        if (!form.price) {this.formError("price", "Gib die Kosten an"); return}
        if (!form.status) {this.formError("status", "Wähle einen Status aus"); return}

        if (form.status === "ordered" || form.dateOrdered) {
            if (!form.dateOrdered.match(/^\d{2}\.\d{2}\.\d{4}$/)) {this.formError("dateOrdered", "Falsches Format (DD.MM.YYYY)"); return}
            if (!moment(str2date(form.dateOrdered)).isValid()) {this.formError("dateOrdered", "Ungültiges Datum"); return}
        }
        if (form.status === "installed" || form.dateInstalled) {
            if (!form.dateInstalled.match(/^\d{2}\.\d{2}\.\d{4}$/)) {this.formError("dateInstalled", "Falsches Format (DD.MM.YYYY)"); return}
            if (!moment(str2date(form.dateInstalled)).isValid()) {this.formError("dateInstalled", "Ungültiges Datum"); return}
        }
        if (form.status === "uninstalled" || form.dateUninstalled || form.soldPrice || form.soldTo) {
            if (!form.uninstalledReason) {this.formError("uninstalledReason", "Wähle einen Grund für die Demontage aus"); return}
            if (!form.dateUninstalled.match(/^\d{2}\.\d{2}\.\d{4}$/)) {this.formError("dateUninstalled", "Falsches Format (DD.MM.YYYY)"); return}
            if (!moment(str2date(form.dateUninstalled)).isValid()) {this.formError("dateUninstalled", "Ungültiges Datum"); return}
            if (form.soldTo && !form.soldPrice) {this.formError("soldPrice", "Gib einen Preis ein, wenn etwas verkauft wurde"); return}
        }

        if (form.status === "ordered" && (form.dateInstalled || form.dateUninstalled)) {this.formError("status", "Fortgeschrittenerer Status wurde bereits definiert"); return}
        if (form.status === "installed" && form.dateUninstalled) {this.formError("status", "Fortgeschrittenerer Status wurde bereits definiert"); return}

        if (form.status === "ordered" || form.status === "installed") {
            if (form.status === "ordered") {
                global.form.dateInstalled = "";
                global.form.installedComment = "";
            }
            global.form.uninstalledReason = "";
            global.form.dateUninstalled = "";
            global.form.uninstalledComment = "";
            global.form.soldPrice = "";
            global.form.soldTo = "";
        }

        const requestForm = this.prepareRequestForm();
        const condition = (formAction === "SET") ? {dateCreated: selectedItem.dateCreated} : {};
        if (formAction === "ADD") requestForm.dateCreated = moment().format("YYYY-MM-DD HH:mm:ss");
        
        await db(formAction, profile.tableName, condition, requestForm);

        msg((formAction === "ADD") ? "Eintrag hinzugefügt" : "Eintrag gespeichert", "success");

        await getTable();
        global.loading = false;
    }

    prepareRequestForm() {
        const {form} = global;

        const requestForm = JSON.parse(JSON.stringify(form));

        for (const key in requestForm) {
            let value = requestForm[key];
            if (value && (key === "price" || key === "soldPrice")) {
                requestForm[key] = str2price(value).toFixed(2);
            } else if (key.startsWith("date")) {
                requestForm[key] = str2date(value);
            } else {
                requestForm[key] = value.clear();
            }
        }

        return requestForm;
    }

    formError(name, text) {
        this.localstate.error = name;
        msg(text, "error");
    }

    render() {
        const {form} = global;
        const {error} = this.localstate;

        return (
            <div id="overlay-form" className="overlay-container">
                <div id="form" data-action="">
                    <div className="flex-row">
                        <div className="radio-box-center">
                            <p className="center">Typ</p>
                            <div className={error === "type" ? "error" : null} onChange={(e) => this.onChange("type", e)}>
                                <label>
                                    <input type="radio" name="form-type" value="tuning" checked={form.type === "tuning"} readOnly/>
                                    <p>Tuning</p>
                                </label>
                                <label>
                                    <input type="radio" name="form-type" value="maintenance" checked={form.type === "maintenance"} readOnly/>
                                    <p>Wartung</p>
                                </label>
                                <label>
                                    <input type="radio" name="form-type" value="repair" checked={form.type === "repair"} readOnly/>
                                    <p>Reparatur</p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-col">
                        <div className="flex-row">
                            <label>
                                <p>Beschreibung</p>
                                <textarea
                                    id="input-form-description"
                                    className={error === "description" ? "error" : null}
                                    type="text"
                                    value={form.description}
                                    maxLength="500"
                                    placeholder="..."
                                    autoComplete="off"
                                    onChange={(e) => this.onChange("description", e)}
                                ></textarea>
                            </label>
                            <label className="label-small">
                                <p>Kosten</p>
                                <input
                                    className={error === "price" ? "error" : null}
                                    type="text"
                                    value={form.price}
                                    maxLength="12"
                                    placeholder="0,00"
                                    autoComplete="off"
                                    onChange={(e) => this.onChange("price", e)}
                                    onBlur={(e) => this.onFocusout("price", e)}
                                />
                            </label>
                        </div>
                        <div className="flex-col">
                            <div id="radio-status" className="radio-box">
                                <p>Status:</p>
                                <div className={error === "status" ? "error" : null} onChange={(e) => this.onChange("status", e)}>
                                    <label>
                                        <input type="radio" name="form-status" value="ordered" checked={form.status === "ordered"} readOnly/>
                                        <p>Auftrag</p>
                                    </label>
                                    <label>
                                        <input type="radio" name="form-status" value="installed" checked={form.status === "installed"} readOnly/>
                                        <p>Verbaut</p>
                                    </label>
                                    <label>
                                        <input type="radio" name="form-status" value="uninstalled" checked={form.status === "uninstalled"} readOnly/>
                                        <p>Demontiert</p>
                                    </label>
                                </div>
                            </div>
                            <div className="flex-row" style={{display: (form.status === "ordered") ? "flex" : "none" }}>
                                <label className="label-small">
                                    <p>Wann?</p>
                                    <input
                                        className={error === "dateOrdered" ? "error" : null}
                                        type="text"
                                        value={form.dateOrdered}
                                        maxLength="10"
                                        placeholder={moment().format("DD.MM.YYYY")}
                                        autoComplete="off"
                                        onChange={(e) => this.onChange("dateOrdered", e)}
                                        onBlur={(e) => this.onFocusout("dateOrdered", e)}
                                    />
                                </label>
                                <label>
                                    <p>Wo?</p>
                                    <input
                                        type="text"
                                        value={form.orderedPlace}
                                        placeholder="Website, Link, Firma, ..."
                                        autoComplete="off"
                                        onChange={(e) => this.onChange("orderedPlace", e)}
                                    />
                                </label>
                            </div>
                            <div className="flex-row" style={{display: (form.status === "installed") ? "flex" : "none" }}>
                                <label className="label-small">
                                    <p>Wann?</p>
                                    <input
                                        className={error === "dateInstalled" ? "error" : null}
                                        type="text"
                                        value={form.dateInstalled}
                                        maxLength="10"
                                        placeholder={moment().format("DD.MM.YYYY")}
                                        autoComplete="off"
                                        onChange={(e) => this.onChange("dateInstalled", e)}
                                        onBlur={(e) => this.onFocusout("dateInstalled", e)}
                                    />
                                </label>
                                <label>
                                    <p>Anmerkung</p>
                                    <input
                                        type="text"
                                        value={form.installedComment}
                                        maxLength="500"
                                        placeholder="Optional"
                                        autoComplete="off"
                                        onChange={(e) => this.onChange("installedComment", e)}
                                    />
                                </label>
                            </div>
                            <div id="radio-uninstalledReason" className="radio-box" style={{display: (form.status === "uninstalled") ? "flex" : "none" }}>
                                <p>Grund:</p>
                                <div className={error === "uninstalledReason" ? "error" : null} onChange={(e) => this.onChange("uninstalledReason", e)}>
                                    <label>
                                        <input type="radio" name="form-uninstalledReason" value="worn" checked={form.uninstalledReason === "worn"} readOnly/>
                                        <p>Verschlissen</p>
                                    </label>
                                    <label>
                                        <input type="radio" name="form-uninstalledReason" value="broken" checked={form.uninstalledReason === "broken"} readOnly/>
                                        <p>Kaputt</p>
                                    </label>
                                    <label>
                                        <input type="radio" name="form-uninstalledReason" value="upgrade" checked={form.uninstalledReason === "upgrade"} readOnly/>
                                        <p>Upgrade</p>
                                    </label>
                                </div>
                            </div>
                            <div className="flex-row" style={{display: (form.status === "uninstalled") ? "flex" : "none" }}>
                                <label className="label-small">
                                    <p>Wann?</p>
                                    <input
                                        className={error === "dateUninstalled" ? "error" : null}
                                        type="text"
                                        value={form.dateUninstalled}
                                        maxLength="10"
                                        placeholder={moment().format("DD.MM.YYYY")}
                                        autoComplete="off"
                                        onChange={(e) => this.onChange("dateUninstalled", e)}
                                        onBlur={(e) => this.onFocusout("dateUninstalled", e)}
                                    />
                                </label>
                                <label>
                                    <p>Anmerkung</p>
                                    <input
                                        type="text"
                                        value={form.uninstalledComment}
                                        maxLength="500"
                                        placeholder="Optional"
                                        autoComplete="off"
                                        onChange={(e) => this.onChange("uninstalledComment", e)}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="flex-row" style={{display: (form.status === "uninstalled") ? "flex" : "none" }}>
                            <label className="label-small">
                                <p>Verkauft für</p>
                                <input
                                    className={error === "soldPrice" ? "error" : null}
                                    type="text"
                                    value={form.soldPrice}
                                    maxLength="12"
                                    placeholder="0,00"
                                    autoComplete="off"
                                    onChange={(e) => this.onChange("soldPrice", e)}
                                    onBlur={(e) => this.onFocusout("soldPrice", e)}
                                />
                            </label>
                            <label>
                                <p>Verkauft an</p>
                                <input
                                    type="text"
                                    value={form.soldTo}
                                    placeholder="Name, Plattform, ... (Optional)"
                                    autoComplete="off"
                                    onChange={(e) => this.onChange("soldTo", e)}
                                />
                            </label>
                        </div>
                    </div>
                    <div className="flex-row">
                        <button id="btn-form-confirm" onClick={() => this.submitForm()}><i className="fal fa-check"></i>Bestätigen</button>
                    </div>
                </div>
                <button className="btn-icon" onClick={() => hideForm()}><i className="fal fa-times"></i></button>
            </div>
        );
    }
}

function round(number, format = false) {
    let rounded = Math.round((number + Number.EPSILON) * Math.pow(10, 2)) / Math.pow(10, 2);
    if (Number.isNaN(rounded)) rounded = 0;
    return (!format) ? rounded : rounded.toString().replace(".", ",");
}

function toFixedLength(str, width = 2) {
    if (str.length > width) str = str.match(/^.{2}/)[0];
    return new Array(+width + 1 - (str + "").length).join("0") + str;
}

// eslint-disable-next-line
String.prototype.clear = function () {
    return this.replace(/\s{2,}/g, " ").replace(/^\s+|\s+$/g, "").replace(/\n/g, "<br>");
}

export default observer(Header);