import $ from "jquery";

import global from "../components/Global";
import db from "./database";

//############################################################################# Get Table

async function getTable() {
    const {profile, selectedItem} = global;

    global.loading = true;

    const dbTable = await db("GET", profile.tableName, {}, []);

    for (let item of dbTable) {
        const date = (item.dateInstalled) ? "dateInstalled" : "dateOrdered";
        item["date"] = `${item[date]} ${item.dateCreated.replace(/(.*)?\s(.*)$/, "$2")}`;
        if (selectedItem && item.dateCreated === selectedItem.dateCreated) global.selectedItem = item;
    }

    global.profile.tableOriginal = dbTable;
    global.profile.table = dbTable;
    sortTable();
    hideForm();
}

//############################################################################# Message

function msg(text = "", status = null) {
    const {message} = global;
    if (!text) return;

    global.message.status = status;
    global.message.text = text;

    $("#message").fadeIn(250);

    clearTimeout(message.timeout);
    
    message.timeout = setTimeout(() => { $("#message").fadeOut(250); }, 4000);
}

//############################################################################# Sorting

function sortTable() {
    const {profile} = global;

    profile.table.sort(sortingCompare);
    profile.tableOriginal.sort(sortingCompare);
}

function sortingCompare(indexPrev, indexNext) {
    const {sorting} = global;

    const invert = (sorting.direction.toUpperCase() === "AZ") ? 1 : -1;
    let prev = indexPrev[sorting.column];
    let next = indexNext[sorting.column];

    if (prev.match(/^-?\d+(\.\d*)?$/)) prev = Number(prev);
    if (next.match(/^-?\d+(\.\d*)?$/)) next = Number(next);
    if (typeof prev === "string") prev = prev.toLowerCase();
    if (typeof next === "string") next = next.toLowerCase();

    return (prev > next) ? 1 * invert : (prev < next) ? -1 * invert : 0;;
}

//############################################################################# Form

function showForm(action = null) {
    const {defaultForm, selectedItem} = global;
    
    if (action === "ADD") global.form = JSON.parse(JSON.stringify(defaultForm));
    if (action === "SET") {
        const newForm = JSON.parse(JSON.stringify(selectedItem));
        delete newForm.dateCreated;
        delete newForm.date;

        for (const key in newForm) {
            let value = newForm[key];
            if (value && (key === "price" || key === "soldPrice")) value = price2str(value);
            if (key.startsWith("date")) value = date2str(value);
            newForm[key] = value;
        }

        global.form = newForm;
    }


    global.formAction = action;
    $("#overlay-form").fadeIn(250);
    focus("#input-form-description");
}

function hideForm() {
    $("#overlay-form").fadeOut(250);
}

//############################################################################# Helper Functions

function str2date(str) {
    return str.replace(/^(\d{2})\.(\d{2})\.(\d{4})/, "$3-$2-$1");
}

function date2str(date) {
    return date.replace(/^(\d{4})-(\d{2})-(\d{2})/, "$3.$2.$1");
}

function price2str(price) {
    if (typeof price === "string") price = Number(price.replace(",", "."));
    return price.toFixed(2).replace(".", ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function str2price(str) {
    return Number(str.replace(/\./g, "").replace(",", "."));
}

function focus(element) {
    if (element) setTimeout(() => { document.querySelector(element).focus(); }, 1);
}

//############################################################################# Export

export {getTable, sortTable, focus, msg, showForm, hideForm, price2str, str2price, str2date, date2str};