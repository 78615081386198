import React from "react";
import ReactDOM from "react-dom/client";

import Header from "./components/Header";
import Table from "./components/Table";
import Form from "./components/Form";
import Profile from "./components/Profile";

import "./fontawesome/pro6/css/all.min.css";
import "./style.css";

const root = ReactDOM.createRoot(
    document.getElementById("root")
);

root.render(
    <>
        <Header/>
        <Table/>
        <Form/>
        <Profile/>
    </>
);