import React, {Component} from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import $ from "jquery";

import global from "./Global";
import {sortTable, focus, showForm} from "../js/functions";

class Header extends Component {

    localstate = observable({
        searchInput: "",
        filter: {
            type: {
                tuning: true,
                maintenance: true,
                repair: true
            },
            status: {
                ordered: true,
                installed: true,
                uninstalled: true
            }
        }
    });

    changeProfile() {
        global.profile = null;
        localStorage.removeItem("profileLogin");
        focus("#input-profile");
    }

    toggleSearch() {
        $("#search-container").slideToggle(200, () => {
            this.localstate.searchInput = "";
            this.onSearch();
            sortTable();
            focus("#input-search");
        });
    }

    onSearch() {
        const {profile} = global;
        const {searchInput, filter} = this.localstate;
        const searchWord = searchInput.clear().toLowerCase();
        const searchList = searchWord.split(" ");
        const searchNotFor = ["price", "soldPrice", "status", "type", "uninstalledReason"];

        if (!searchInput) profile.table = profile.tableOriginal;
    
        profile.table = profile.tableOriginal.filter(item => {
            let includesCounter = 0;
            for (const key in item) {
                if (searchNotFor.includes(key)) continue;
                for (const searchWord of searchList) {
                    if (item[key]?.toLowerCase()?.includes(searchWord)) includesCounter ++;
                }
            }
            if (includesCounter < searchList.length) return false;
        
            for (const key in filter) {
                for (const value in filter[key]) {
                    if (!filter[key][value] && item[key] === value) return false;
                }
            }
            return true;
        });
    }

    onFilter(key, value, state) {
        const {filter} = this.localstate;
        filter[key][value] = state;
        this.onSearch();
    }

    render() {
        const {profile} = global;
        const {searchInput, filter} = this.localstate;

        return (
            <div id="header">
                <h1>{profile?.name || "Dein Fahrzeug"}</h1>
                <div className="flex-row">
                    <button id="btn-add" onClick={() => showForm("ADD")}><i className="fad fa-plus"></i>Neu</button>
                    <button id="btn-profile-change" onClick={() => this.changeProfile()}><i className="fad fa-cars"></i>Fahrzeug</button>
                    <button id="btn-search" onClick={() => this.toggleSearch()}><i className="far fa-search"></i><i className="fad fa-slash-forward"></i><i className="far fa-filter"></i></button>
                </div>
                <div id="search-container">
                    <label id="input-search-label">
                        <input
                            id="input-search"
                            type="text"
                            value={searchInput}
                            placeholder="Suche ..."
                            autoComplete="off"
                            onChange={(e) => {
                                this.localstate.searchInput = e.target.value;
                                this.onSearch();
                            }}
                        />
                    </label>
                    <div id="filter-container">
                        <div className="filter-group">
                            <label className="checkbox-label">
                                <input type="checkbox" checked={filter.type.tuning} onChange={(e) => this.onFilter("type", "tuning", e.target.checked)}/>
                                <div className="checkbox-indicator"></div>
                                <p>Tuning</p>
                            </label>
                            <label className="checkbox-label">
                                <input type="checkbox" checked={filter.type.maintenance} onChange={(e) => this.onFilter("type", "maintenance", e.target.checked)}/>
                                <div className="checkbox-indicator"></div>
                                <p>Wartung</p>
                            </label>
                            <label className="checkbox-label">
                                <input type="checkbox" checked={filter.type.repair} onChange={(e) => this.onFilter("type", "repair", e.target.checked)}/>
                                <div className="checkbox-indicator"></div>
                                <p>Reparatur</p>
                            </label>
                        </div>
                        <div className="filter-group">
                            <label className="checkbox-label">
                                <input type="checkbox" checked={filter.status.ordered} onChange={(e) => this.onFilter("status", "ordered", e.target.checked)}/>
                                <div className="checkbox-indicator"></div>
                                <p>Auftrag</p>
                            </label>
                            <label className="checkbox-label">
                                <input type="checkbox" checked={filter.status.installed} onChange={(e) => this.onFilter("status", "installed", e.target.checked)}/>
                                <div className="checkbox-indicator"></div>
                                <p>Montiert</p>
                            </label>
                            <label className="checkbox-label">
                                <input type="checkbox" checked={filter.status.uninstalled} onChange={(e) => this.onFilter("status", "uninstalled", e.target.checked)}/>
                                <div className="checkbox-indicator"></div>
                                <p>Demontiert</p>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// eslint-disable-next-line
String.prototype.clear = function () {
    return this.replace(/\s{2,}/g, " ").replace(/^\s+|\s+$/g, "");
}

export default observer(Header);