import {makeAutoObservable, configure} from "mobx";

configure({
    enforceActions: "never"
});

class global {
    varName = {
        tuning: "Tuning",
        maintenance: "Wartung",
        repair: "Reparatur",
        ordered: "Auftrag",
        installed: "Verbaut",
        uninstalled: "Demontiert",
        sold: "Verkauft",
        worn: "Verschlissen",
        broken: "Kaputt",
        upgrade: "Upgrade"
    }
    
    icon = {
        tuning: "steering-wheel",
        maintenance: "oil-can-drip",
        repair: "screwdriver-wrench",
        ordered: "file-check",
        installed: "car",
        uninstalled: "wrench",
        sold: "dollar-sign"
    }

    sorting = {
        column: "date",
        direction: "ZA"
    }

    defaultForm = {
        description: "",
        type: "",
        price: "",
        status: "",
        orderedPlace: "",
        installedComment: "",
        uninstalledReason: "",
        uninstalledComment: "",
        soldPrice: "",
        soldTo: "",
        dateOrdered: "",
        dateInstalled: "",
        dateUninstalled: ""
    }

    form = JSON.parse(JSON.stringify(this.defaultForm));
    
    profile = null;
    loading = false;
    selectedItem = null;
    itemDetails = null;
    formAction = null;
    mobile = window.outerWidth <= 900;

    message = {
        status: null,
        text: "",
        timeout: null
    }

    constructor() {
        makeAutoObservable(this);
    }
}

const data = new global();
// const data = window.data = new global();

export default data;