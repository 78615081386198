import React, {Component} from "react";
import {observer} from "mobx-react";
import {observable} from "mobx";
import moment from "moment";

import global from "./Global";
import {getTable, focus, msg} from "../js/functions";
import db from "../js/database";

class Login extends Component {

    localstate = observable({
        input: ""
    });

    async checkProfile() {
        const {input} = this.localstate;

        const dbProfiles = await db("GET", "profiles", { login: input.toLowerCase().replace(/\s+/g, "") }, []);

        if (dbProfiles.length > 0) {
            global.profile = dbProfiles[0];

            await db("SET", "profiles", { dateCreated: global.profile.dateCreated }, { dateUsed: moment().format("YYYY-MM-DD HH:mm:ss") });
            localStorage.setItem("profileLogin", global.profile.login);

            await getTable();

            this.localstate.input = "";
        } else {
            msg("Es wurde kein Profil gefunden", "error");
        }
    
        global.loading = false;
        focus("#input-profile");
    }

    componentDidMount() {
        if (localStorage.getItem("profileLogin")) {
            this.localstate.input = localStorage.getItem("profileLogin");
            this.checkProfile();
        }
        
        focus("#input-profile");
    }

    render() {
        const {input} = this.localstate;
        const {profile, loading, message} = global;
        
        return (
            <>
                <div id="profile-container" style={{ display: (profile) ? "none" : "flex" }}>
                    <div id="profile">
                        <h1>Dein Profil</h1>
                        <label>
                            <input 
                                id="input-profile"
                                type="text"
                                value={input}
                                placeholder="..."
                                disabled={loading}
                                autoComplete="off"
                                onChange={(e) => this.localstate.input = e.target.value}
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") this.checkProfile();
                                }}
                            />
                        </label>
                        <div className="flex-row">
                            <button id="btn-profile" onClick={() => this.checkProfile()}><i className="fal fa-check"></i>Wählen</button>
                        </div>
                    </div>
                </div>
                <div id="message" data-status={message.status}>
                    <p>{message.text}</p>
                </div>
            </>
        );
    }
}

export default observer(Login);