import React, {Component} from "react";
import {observer} from "mobx-react";
import moment from "moment";
import $ from "jquery";

import global from "./Global";
import {sortTable, showForm, msg, getTable, price2str} from "../js/functions";
import db from "../js/database";

class Table extends Component {

    selectItem(item) {
        const {selectedItem, mobile} = global;
        
        if (item.dateCreated !== selectedItem?.dateCreated) {
            global.selectedItem = item;
            if (mobile) setTimeout(() => { $("#details-container").slideDown(200); }, 1);
        } else {
            if (mobile){
                $("#details-container").slideUp(200, () => {
                    global.selectedItem = null;
                });
            } else {
                global.selectedItem = null;
            }
        }
    }

    onSort(e) {
        const {column, direction} = global.sorting;

        if (column === e.currentTarget.className) {
            global.sorting.direction = (direction === "AZ") ? "ZA" : "AZ";
        } else {
            global.sorting.direction = "AZ";
        }

        global.sorting.column = e.currentTarget.className;
        sortTable();
    }

    async deleteItem() {
        const {profile, selectedItem} = global;

        const sure = window.confirm("Eintrag wirklich löschen?");
        if (!sure) return;

        await db("DELETE", profile.tableName, { dateCreated: selectedItem.dateCreated });

        msg("Eintrag gelöscht", "success");

        global.selectedItem = null;
        await getTable();
        global.loading = false;
    }

    render() {
        const {profile, varName, icon, selectedItem, loading, mobile} = global;
        const {column, direction} = global.sorting;

        return (
            <div className="table-container">
                <table data-loading={loading}>
                    <thead>
                        <tr>
                            <td className="type" data-sortdir={column === "type" ? direction : null} onClick={(e) => this.onSort(e)}>{(mobile) ? <i className="fal fa-car-wrench"></i> : "Typ"}</td>
                            <td className="description" data-sortdir={column === "description" ? direction : null} onClick={(e) => this.onSort(e)}>Beschreibung</td>
                            <td className="price" data-sortdir={column === "price" ? direction : null} onClick={(e) => this.onSort(e)}>Kosten</td>
                            <td className="status" data-sortdir={column === "status" ? direction : null} onClick={(e) => this.onSort(e)}>{(mobile) ? <i className="fal fa-check-circle"></i> : "Status"}</td>
                            <td className="date" data-sortdir={column === "date" ? direction : null} onClick={(e) => this.onSort(e)}>Datum</td>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        profile?.table && profile.table.map((x ,i) => {
                            const soldAttr = (x.soldPrice) ? price2str(x.soldPrice) : null;
                            
                            return (
                                <tr 
                                    key={i}
                                    className={(x.dateCreated === selectedItem?.dateCreated) ? "selected" : ""}
                                    data-datecreated={x.dateCreated}
                                    data-status={x.status}
                                    data-sold={soldAttr}
                                    onClick={() => this.selectItem(x)}
                                >
                                    <td className="type" title={varName[x.type]}><i className={`fal fa-${icon[x.type]}`}></i></td>
                                    <td className="description">{x.description}</td>
                                    <td className="price" data-sold={soldAttr}>{(Number(x.price )> 0) ? `${price2str(x.price)} €` : <i className="fas fa-gift"></i>}</td>
                                    <td className="status" data-status={x.status} title={varName[x.status]}><i className={`fas fa-${icon[x.status]}`}></i></td>
                                    <td className="date">
                                        {(x.dateUninstalled) ? <span><i className="fas fa-wrench"></i> {x.dateUninstalled.format(true)}</span> : null}
                                        {(x.dateInstalled) ? <span><i className="fas fa-car"></i> {x.dateInstalled.format(true)}</span> : null}
                                        {(x.dateOrdered) ? <span><i className="fas fa-file-check"></i> {x.dateOrdered.format(true)}</span> : null}
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="type"></td>
                            <td className="description">Gesamt: </td>
                            <td className="price">{(profile?.table) ? price2str(profile.table.reduce((a, b) => a + Number(b.price) - Number(b.soldPrice), 0)) : "0,00"} €</td>
                            <td className="status"></td>
                            <td className="date"></td>
                        </tr>
                    </tfoot>
                </table>
                <div id="details-container" style={{ display: (mobile) ? "none" : "flex" }}>
                    {
                        (selectedItem) ? 
                            <>
                                <div className="flex-row flex-between">
                                    <button id="btn-set" className="btn-white" onClick={() => showForm("SET")}><i className="fad fa-pencil"></i>Bearbeiten</button>
                                    <button id="btn-delete" className="btn-white" onClick={() => this.deleteItem()}><i className="fad fa-trash"></i>Löschen</button>
                                </div>
                                <div className="flex-row">
                                    <div className="flex-col">
                                        <blockquote>
                                            <div className="details-head">
                                                <i className={`fal fa-${icon[selectedItem.type]}`}></i>
                                                <div>
                                                    <h1>{varName[selectedItem.type]}</h1>
                                                    <p><i className={`fas fa-${icon[selectedItem.status]}`}></i>{varName[selectedItem.status]}</p>
                                                    <p className="price">{price2str(selectedItem.price)}</p>
                                                </div>
                                            </div>
                                            <p className="description">{selectedItem.description}</p>
                                        </blockquote>
                                    </div>
                                    <div className="flex-col">
                                        {
                                            (selectedItem?.dateOrdered) ? 
                                                <blockquote>
                                                    <span><i className={`fas fa-${icon.ordered}`}></i>{varName.ordered} {selectedItem.dateOrdered.format()}</span>
                                                    <p >{(selectedItem.orderedPlace?.match(/http|www|\.de\//)) ? <a href={selectedItem.orderedPlace} rel="noreferrer" target="_blank">{selectedItem.orderedPlace}</a> : selectedItem.orderedPlace}</p>
                                                </blockquote>
                                            : null
                                        }
                                        {
                                            (selectedItem.dateInstalled) ? 
                                                <blockquote>
                                                    <span><i className={`fas fa-${icon.installed}`}></i>{varName.installed} {selectedItem.dateInstalled.format()}</span>
                                                    <p>{selectedItem.installedComment}</p>
                                                </blockquote>
                                            : null
                                        }
                                        {
                                            (selectedItem.dateUninstalled && !selectedItem.soldPrice) ? 
                                                <blockquote>
                                                    <span><i className={`fas fa-${icon.uninstalled}`}></i>{varName.uninstalled} {selectedItem.dateUninstalled.format(true)}</span>
                                                    <p>Grund: {varName[selectedItem.uninstalledReason]}</p>
                                                    <p>{selectedItem.uninstalledComment}</p>
                                                </blockquote>
                                            : null
                                        }
                                        {
                                            (selectedItem.dateUninstalled && selectedItem.soldPrice) ? 
                                                <blockquote>
                                                    <span><i className={`fas fa-${icon.sold}`}></i>{varName.uninstalled} & {varName.sold.toLowerCase()} am {selectedItem.dateUninstalled.format()}</span>
                                                    <p>Grund: {varName[selectedItem.uninstalledReason]}</p>
                                                    <p>Für {price2str(selectedItem.soldPrice)} € an {selectedItem.soldTo}</p>
                                                    <p>{selectedItem.uninstalledComment}</p>
                                                </blockquote>
                                            : null
                                        }
                                    </div>
                                </div>
                            </>
                        : null
                    }
                </div>
            </div>
        );
    }
}

// eslint-disable-next-line
String.prototype.format = function (short = false) {
    return moment(this).format(`DD.MM.${(short) ? "YY" : "YYYY"}`);
}

export default observer(Table);